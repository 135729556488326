class ThemeManager {
    private html: HTMLElement;
    private themeIcon: HTMLElement | null = null;
    private currentTheme: string;
    private themeToggle: HTMLElement | null = null;

    constructor() {
        this.html = document.documentElement;
        this.currentTheme = localStorage.getItem('theme') || 'light';
        this.init();
    }

    private init(): void {
        // 等待 DOM 完全加载
        if (document.readyState === 'loading') {
            document.addEventListener('DOMContentLoaded', () => this.setupTheme());
        } else {
            this.setupTheme();
        }

        // 监听动态加载的元素
        const observer = new MutationObserver(() => {
            this.setupThemeButton();
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
    }

    private setupTheme(): void {
        this.setTheme(this.currentTheme);
        this.setupThemeButton();
    }

    private setupThemeButton(): void {
        this.themeToggle = document.getElementById('themeToggle');
        this.themeIcon = document.querySelector('.theme-icon');

        if (this.themeToggle && !this.themeToggle.dataset.initialized) {
            this.themeToggle.addEventListener('click', (e) => {
                e.preventDefault();
                this.toggleTheme();
            });
            this.themeToggle.dataset.initialized = 'true';
            this.updateThemeIcon();
        }
    }

    private setTheme(theme: string): void {
        this.html.setAttribute('data-theme', theme);
        localStorage.setItem('theme', theme);
        this.currentTheme = theme;
        this.updateThemeIcon();

        // 更新其他主题相关的样式
        if (theme === 'dark') {
            document.body.style.backgroundColor = '#1a1a1a';
            document.body.style.color = '#ffffff';
        } else {
            document.body.style.backgroundColor = '#ffffff';
            document.body.style.color = '#2d3436';
        }
    }

    private updateThemeIcon(): void {
        if (this.themeIcon) {
            this.themeIcon.className = `ri-${this.currentTheme === 'light' ? 'sun' : 'moon'}-line theme-icon`;
        }
    }

    private toggleTheme(): void {
        const newTheme = this.currentTheme === 'light' ? 'dark' : 'light';
        this.setTheme(newTheme);
    }
}

// 创建单例实例
const themeManager = new ThemeManager();

export default themeManager;
